import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { login } from '../auth_module/actions/auth';
import { AuthModuleRoutes } from '../auth_module/AuthModuleRoutes';
import ContainerLayout from '../framework/layout/ContainerLayout';
import PrincipalLayout from '../framework/layout/PrincipalLayout';
import { StorageUtil } from '../util/StorageUtil';
import { UrlUtil } from '../util/UrlUtil';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export const AppRouter = ({ match, location }) => {    
    console.log('AppRouter');
    const dispatch = useDispatch();
    const [ isLoggedIn, setIsLoggedIn ] = React.useState(false);

    StorageUtil.setItemObject('USER_BAR', {
        title: 'Cuenta',
        fields: [
            { label: 'Ajustes', icon: 'fas fa-user' },
            { label: 'Logout', icon: 'icon-user', onclick: "" }
        ]
    });

    React.useEffect(() => {
        let auth = StorageUtil.getItemObject('AUTH');
        if (auth){
            dispatch( login( auth.username, auth.displayName ) );
            setIsLoggedIn( true );
        }
        else{
            setIsLoggedIn( false );
        }
    }, [ dispatch, setIsLoggedIn ]);


    const params = UrlUtil.paramsUrlToObject();
    let full = window.sessionStorage.getItem("full");
    if (params['full'] === 2){
        full = null;
    }
    else if (params['full'] === 1){
        full = 1;
    }

    if (full === 1){
        window.sessionStorage.setItem("full", 1);
        /* persistir en sesion la primera vez que entra */
        return (
            <Switch>
                <Route path="/" component={ContainerLayout} />    
            </Switch>
        )
    }
    else{
        window.sessionStorage.setItem("full", null);
        return (
            <Switch>   
                <PublicRoute path="/auth" component={ AuthModuleRoutes } isAuthenticated={ isLoggedIn } />
                <PrivateRoute isAuthenticated={ isLoggedIn } path="/" component={ PrincipalLayout } />
                <Redirect to="/auth/login" />
            </Switch>
        )
    }
}