
//import { startLoading, finishLoading } from './ui';
import { types } from "../../type/types";
import { StorageUtil } from "../../util/StorageUtil";

export const startLoginUsernamePassword = (username, password) => {
    return (dispatch) => {
        console.log('username',username);
        setTimeout(()=> {
            StorageUtil.setItemObject('AUTH', {user:{username:'Pedro'}});    
        },3500);
    }
}

export const login = (uid, displayName) => {
    return {
        type: types.login,
        payload: {
            uid,
            displayName
        }
    };
};


export const startLogout = () => {
    return async( dispatch ) => {
        //await firebase.auth().signOut();

        dispatch( logout() );
    }
}


export const logout = () => ({
    type: types.logout
})


