import React from 'react';
import './LoginPage.css';
/* import { Form, Input, Button, Checkbox, Row, Col, Card } from 'antd'; */
import Layout, { Content } from 'antd/lib/layout/layout';
import { useDispatch } from 'react-redux';
//import { startLoginUsernamePassword } from '../actions/auth';
import { RequestUtil } from '../../util/RequestUtil';
import { EnvConstants } from '../../EnvConstants';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { SecurityUtil } from '../../util/SecurityUtil';
import { Link } from 'react-router-dom';
import './LoginPage.css';
import logo from '../../assets/img/logo_bv.jpeg';
const LoginPage = (props) => {

	const [loginForm, handleInputChange, reset] = useForm({ username: '', password: '', rememberMe: true });
	const history = useHistory();
	const dispatch = useDispatch();
	/*   const [form] = Form.useForm(); */
	const onFinish = (values) => {
		console.log('Success:', values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const login = async (e) => {
		e.preventDefault();
		if (loginForm['username'] !== '' && loginForm['password'] !== '') {
			try {
				let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", loginForm);
				if (resp) {
					SecurityUtil.setLogin(resp);
				}
			} catch (error) {
				//NotificationUtil.error('Usuario o Password erróneo');
				//alert('Usuario o Password erróneo');
			}
		} else {

		}
	}

	React.useEffect(() => {
		// setRemember(true);
	}, [loginForm])

	return (
		<>
			<div className="login__fondo__img">
				<div className="login__wrapper">
					<div className="login__form">
						<div className="login__content">
							<center>
							<img src={logo}  className="login__img"/>
							</center>
							
							<form
								className="form-content"
								/* onSubmit={handleLogin}  */
								onSubmit={login}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
							>
								<div className="group">
									<input type="text" name="username" autoComplete="off" onChange={handleInputChange} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label>Username</label>
								</div>

								<div className="group">
									<input type="password" name="password" onChange={handleInputChange} />
									<span className="highlight"></span>
									<span className="bar"></span>
									<label>Password</label>
								</div>
								<div className="login__options">
									<input type="checkbox" name="rememberMe" />
									<label htmlFor="remember_me"> Remember me </label>
									<Link to="!#"> Forgot password? </Link>
								</div>
								<button type="submit" className="login__button">Login</button>
								<p>
									Dont got an account yet?
								<br />
									<Link to="!#"> Create an account </Link>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>


	);
};

export default LoginPage;
