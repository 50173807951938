export class UrlUtil {
    static getHostName = () => {
        return window.location.hostname;
    }
    static getProtocol = () => {
        return window.location.protocol;
    }
    static getHost = () => {
        return window.location.host;
    }
    static getPath = () => {
        return window.location.pathname;
    }
    static getSearch = () => {
        return window.location.search;
    }
    static getLastPath = (url) => {
        url = url || UrlUtil.getPath();
        let paths = url.split('/');
        return paths[paths.length-1];
    }
    static getPreviousLastPath = (url) => {
        url = url || UrlUtil.getPath();
        let paths = url.split('/');
        return paths.length>1 ? paths[paths.length-2]:null;
    }
    static objectToParamsUrl = (obj) => {
        let params = new URLSearchParams();
        for (let key in obj) {
            if (obj[key]){
                params.set(key, obj[key]);
            }
        }
        return params.toString();
    }
    static paramsUrlToObject = () => {
        let result = {};
        let params = UrlUtil.getSearch();
        if (params){
            params.substring(1)
            .split("&")
            .forEach(param => {
                let pair = param.split("=");
                result[pair[0]] = pair[1];
            });
        }
        return result;
    }

    static createUrlWithObject = (url, params) => {
        console.log(url);
        console.log(params);
        return `${url}?${UrlUtil.objectToParamsUrl(params)}`;
    }
}