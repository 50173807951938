import './ContainerLayout.css';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { ContainerRoutes } from '../../routers/ContainerRoutes';


const ContainerLayout = () => {
    return (
        <Content className="container-layout" >
          <ContainerRoutes/>
        </Content>
    );
};

export default ContainerLayout;