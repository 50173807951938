import { FolderOpenOutlined, FolderOutlined, ManOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import {NavLink } from 'react-router-dom';
import { SecurityUtil } from '../../../util/SecurityUtil';
import { StorageUtil } from '../../../util/StorageUtil';

const getMenuConfig  = (data) => {
	// debugger
	let menus = data;
	let map = {};
	let fields = [];
	//{code: 4, parent: 3, label: "Formularios1", url: "/ex/formularios/form1", icon: "icon-user", fields: []}
	menus.forEach((menu, index) => {
		menu.key = `menu_${index}`;
		map[menu.code] = menu;
	});
	menus.forEach(menu => {
		if (menu.parent == 0){
			menu.fields = [];
			fields.push(menu);
		}
		else {
			// debugger;
			map[menu?.parent]?.fields?.push(menu);
		}
	});
	return {
		fields: fields
	};
}

const createItem = (config)=> (
	<Menu.Item key={config.key} icon={<i className={config.icon}></i>} >
		<NavLink exact to={config.url} >
			{config.label}
		</NavLink>
	</Menu.Item>
);

const createFolder = (config, setState, state) => {
	return (
		<Menu.SubMenu 
			key={config.key} 
			icon={state.find(key => key === config.key) ? <FolderOpenOutlined /> : <FolderOutlined /> }  
			title={config.label}
		>
			{createFolderItem(config)}
		</Menu.SubMenu>
	);
}

const createFolderItem = (config, setState, state)=> {
	let childrenJsx = config.fields.map((childConfig) => {
		const { fields } = childConfig;
		if (fields && fields.length > 0){
			return createFolder(childConfig, setState, state);
		}
		else{
			return createItem(childConfig);
		}
	});
	return 	childrenJsx;

};

const MenuLayout = () => {
	const [config, setConfig ] = React.useState(null);
	const [openKeys, setOpenKeys] = React.useState([]);

	const onOpenChange = keys => {
		console.log('onOpenChange',keys);
		setOpenKeys(keys);
		StorageUtil.setItemObject('MENU_STATE', keys);
	};

	React.useEffect(() => {
		let data = SecurityUtil.getMenu();
		if (data){
			let openKeysTemp = StorageUtil.getItemObject('MENU_STATE');
			if (openKeysTemp)
				setOpenKeys(openKeysTemp);
			setConfig(getMenuConfig(data));
		}
	}, [setOpenKeys]);
	return (
		!config || (
			<Menu 
				theme="dark" 
				mode="vertical" 
				/* onOpenChange={onOpenChange}
				openKeys={openKeys} */
			>
				{createFolderItem(config, setOpenKeys, openKeys)}
			</Menu>
		)
	);
};

export default MenuLayout;