import { StorageUtil } from "./StorageUtil";
import { WindowUtil } from "./WindowUtil";

export class SecurityUtil {
    static async setLogin(resp){
        console.log('setLogin');
        await StorageUtil.setItemObject('AUTH_USER', resp.info?.dataObject?.user);
        await StorageUtil.setItemObject('AUTH_MENU', resp.info?.dataObject?.menu);
        resp.info = null;
        await StorageUtil.setItemObject('AUTH', resp);
        WindowUtil.reload();
    }
    static async setLogout(){
        console.log('setLogout');
        await StorageUtil.removeItemObject('AUTH');
        await StorageUtil.removeItemObject('AUTH_USER');
        await StorageUtil.removeItemObject('AUTH_MENU');
        WindowUtil.reload();
    }
    static getMenu(){
        return StorageUtil.getItemObject('AUTH_MENU');
    }
    static getAccessToken(){
        return StorageUtil.getItemObject('AUTH')?.accessToken;
    }
    static getUsername(){
        return StorageUtil.getItemObject('AUTH')?.userName;
    }
    static getUser(){
        return StorageUtil.getItemObject('AUTH_USER');
    }
}
