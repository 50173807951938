import React, { lazy, Suspense } from 'react'
import {
    Switch,
    Route
  } from "react-router-dom"

const MantainerRoutes = lazy(() => import('./mantainers/MantainerRoutes'));
const ProcessesRoutes = lazy(() => import('./processes/ProcessesRoutes'));
const Permissions = lazy(() => import ('./permissions/PermissionsRoutes'));
const Inbox = lazy(() => import ('./inbox/inboxRoutes'));


export const ModulesRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              
                <Route path="/mantainers" component={MantainerRoutes} /> 
                <Route path="/processes" component={ProcessesRoutes} /> 
                <Route path="/permissions" component={Permissions} />
                <Route path="/inbox" component={Inbox} />
            </Switch>
        </Suspense>
    )
}
//https://learnwithparam.com/blog/how-to-handle-query-params-in-react-router/