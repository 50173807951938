export class WindowUtil {
    static openWindow = (url) => {
        WindowUtil.getWindow().open(url);
    }
    static getWindow = () => {
        return window;
    }
    static reload = () => {
        window.location.reload()
    }
}