import './HeaderLayout.css';
import React from 'react';
import { Layout} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import UserBarFw from '../../user-bar/UserBarFw';

const HeaderLayout = ({config}) => {
    const {state, setState } = config;

    const handleToggle = (e) => {
        setState({
            collapsed: !state.collapsed,
        });
    };

    return (
        <Layout.Header className="header-layout" style={{ position: 'fixed'}} >
            <div>
                <span>
                    {
                        React.createElement(state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: handleToggle
                        })
                    }
                </span>

                <span className="header-layout-user">
                    <UserBarFw/>
                </span>
            </div>
        </Layout.Header>
    );
};

export default HeaderLayout;